import React from 'react';
import {FaBootstrap,FaCss3,FaGit,FaGithub,FaGitlab,FaHtml5,FaJs,FaLaravel,FaLinux,FaNpm,FaReact,FaUbuntu,FaUnity,FaWindows,} from 'react-icons/fa';

function ToolsProgramming() {
    return (
        <div className='flex flex-col gap-8'>
            <h4 className='text-slate-300 text-xl mb-4'>Tools And Programming</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    <FaJs size={80} style={{ color: "#F3C920" }} title='Javascript' />
                    <FaLaravel size={80} style={{ color: "#F33020" }} title='Laravel' />
                    <FaNpm size={80} style={{ color: "#B3ABB8" }} title='NPM' />
                    <FaUnity size={80} style={{ color: "white" }} title='Unity' />
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    <FaHtml5 size={80} style={{ color: "#F36620" }} title='Html' />
                    <FaCss3 size={80} style={{ color: "#2083F3" }} title='CSS' />
                    <FaReact size={80} style={{ color: "#2083F3" }} title='React Js' />
                    <FaBootstrap size={80} style={{ color: "#A020F3" }} title='Bootstrap' />
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    <FaGithub size={80} style={{ color: "#FFFFFF" }} title='Github' />
                    <FaGitlab size={80} style={{ color: "#F36620" }} title='Gitlab' />
                    <FaGit size={80} style={{ color: "#F36620" }} title='Git' />
                </div>
            </div>
            <h4 className='text-slate-300 text-xl mb-4'>Operating System</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="grid grid-cols-3 gap-4">
                    <FaUbuntu size={80} style={{ color: "#F36620" }} title='Ubuntu' />
                    <FaLinux size={80} style={{ color: "white" }} title='Linux' />
                    <FaWindows size={80} style={{ color: "white" }} title='Windows' />
                </div>
            </div>
        </div>
    );
}

export default ToolsProgramming;
